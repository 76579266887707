const cosmetologyPricesAnn = [
    {
        _id: `001`,
        name: `Массаж скульптурный (длит. 40 мин.)`,
        price: 1700
    },
    {
        _id: `002`,
        name: `Массаж буккальный`,
        price: 2800
    },
    {
        _id: `003`,
        name: `Маска альгинатная`,
        price: 500
    },
    {
        _id: `004`,
        name: `Коллагеновая биопластина`,
        price: 500
    },
    {
        _id: `005`,
        name: `Процедура мультимаскинг (суперувлажнение+лифтинг эффект) демакияж, эксфолиация, коллагеновая биопластина и альгинатная маска`,
        price: 2100
    },
    {
        _id: `006`,
        name: `Удаление милиума`,
        price: `150 / 1шт`
    },
    {
        _id: `007`,
        name: `Коррекция бровей`,
        price: 400
    },
    {
        _id: `008`,
        name: `Окрашивание бровей или ресниц`,
        price: 450
    },
    {
        _id: `009`,
        name: `Пилинг:`,
        price: null
    },
    {
        _id: `010`,
        name: `Азелаиновый пилинг`,
        price: 2500
    },
    {
        _id: `011`,
        name: `Гликолевый пилинг 30%`,
        price: 1700
    },
    {
        _id: `012`,
        name: `Осветляющий пилинг`,
        price: 1500
    },
    {
        _id: `013`,
        name: `Миндальный пилинг 30%`,
        price: 2000
    },
    {
        _id: `014`,
        name: `Пилинг джеснера`,
        price: 3500
    },
    {
        _id: `015`,
        name: `Энзимный пилинг`,
        price: 1000
    },
    {
        _id: `016`,
        name: `Депиляция воском:`,
        price: null
    },
    {
        _id: `017`,
        name: `Губа, верх или низ`,
        price: 400
    },
    {
        _id: `018`,
        name: `Подмышки`,
        price: 500
    },
    {
        _id: `019`,
        name: `Бедра`,
        price: 900
    },
    {
        _id: `020`,
        name: `Бикини линия купальника`,
        price: 1000
    },
    {
        _id: `021`,
        name: `Глубокое бикини`,
        price: 1800
    },
    {
        _id: `022`,
        name: `Голень`,
        price: 700
    },
    {
        _id: `023`,
        name: `Мезотерапия (витаминный комплекс)`,
        price: 4000
    },
    {
        _id: `024`,
        name: `Гинкго Билоба (паравертебрально)`,
        price: 700
    },
    {
        _id: `025`,
        name: `Биоревитализация:`,
        price: null
    },
    {
        _id: `026`,
        name: `Revi  ГК 1%`,
        price: 10000
    },
    {
        _id: `027`,
        name: `Revi Silk ГК 1,2%`,
        price: 13000
    },
    {
        _id: `028`,
        name: `Revi Strong ГК 1,5%`,
        price: 15000
    },
    {
        _id: `029`,
        name: `Revi Eye (глаза)`,
        price: 10000
    }
];

export default cosmetologyPricesAnn;
