// import photo1 from "../assets/images/comments-persons/01.jpg";
// import photo2 from "../assets/images/comments-persons/02.jpg";
// import photo3 from "../assets/images/comments-persons/03.jpg";
// import photo4 from "../assets/images/comments-persons/04.jpg";
// import photo5 from "../assets/images/comments-persons/05.jpg";
// import photo6 from "../assets/images/comments-persons/06.jpg";

const reviews = [
    {
        name: "От пациента Карпова П.Н.",
        content:
            "Волшебница Жанна Микаеловна! Благодарю Вас за молниеносный ремонт, скола моста. Желаю Вам здоровья и финансовой стабильности."
    },
    {
        name: "От пациентки Красниковой Л.Н.",
        content:
            "Хочу выразить огромную благодарность доктору Мурадян Жанне Микаеловне за ее лечение, внимание, отзывчивость. Очень внимательный доктор. Профессионал своего дела. Очень рада, что попала на прием к этому доктору. Еще раз спасибо!!!"
    },
    {
        name: "От пациента Соколова М.Н.",
        content:
            "Огромное спасибо доктору  Жанне Микаеловне Мурадян. Отлично и профессионально восстановила зубы. Еще раз огромное спасибо!!!"
    },
    {
        name: "От пациентки Лобановой Н.В.",
        content:
            "Хочу выразить огромную благодарность стоматологу  ортопеду Мурадян Жанее Микаеловне за прекрасно выполненную работу. Это просто врач с большой буквы. Профессионал своего дела. Буду рекомендовать ее всем своим родным, знакомым, друзьям."
    },
    {
        name: "От пациентки Ивановой Т.Ю.",
        content:
            "Хочу выразить огромную благодарность своему лечащему врачу Мурадян Жанне Микаеловне за ее труд. Спасибо, что спасли зуб, теперь только к ней. Огромное спасибо!!!"
    },
    {
        name: "От пациентов Гордеевых",
        content:
            "Огромное спасибо Жанне Микаеловне за удаление  двух зубов у ребенка! Выходим улыбаясь, с хорошим настроением!"
    },
    {
        name: "От пациента Жидкова Ю.П.",
        content: "Спасибо  доктору Мурадян Жанне Микаеловне за очень хорошую работу и отношение к пациенту. Удачи и здоровья!"
    },
    {
        name: "От пациента Барышниковой Марии",
        content: "Жанна – настоящий профессионал и просто ангел! Спасибо, что вы такие есть и что лечите нас в выходные и праздники!"
    },
    {
        name: "От пациентки Пушкиной Виктории",
        content: "Замечательный врач!! Мурадян Жанна Микаевловна!!! Тот самый случай, когда посещение стоматолога оказалось приятным!!! Успехов и процветания!!!"
    },
    {
        name: "От пациентки Башмаковой В.Р.",
        content: "Я очень благодарна Жанне Микаеловне за столь профессиональную работу, спасибо огромное за безболезненное лечение и качество. Ходим к Вам всей семьей и только к Вам. Вы супер!!! СПАСИБО!!! "
    },
    {
        name: "От пациентки Тулягановой Олеси",
        content: "Нет таких слов, чтобы выразить благодарность Жанне Микаевловне. Это врач от бога!!! Она потрясающая кудесница, грамотная, терпеливая и просто лучшая из лучших. Я в восторге от проделанной работы, Жанна сделала невозможное. Я теперь могу улыбаться Урааааа! Спасибо 1000 раз."
    },
    {
        name: "От пациентки Кальчужной Натальи",
        content: "Огромная благодарность Жанне Микаеловне за прекрасную работу. Все пломбы стоят, делать было не больно. Очень заботливый  врач. Качественная и великолепная работа. Спасибо!"
    },

];

export default reviews;
