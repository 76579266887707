import React from "react";

const about = (
    <>
        Рады приветствовать Вас в нашем медицинском учреждении! У нас Вы можете
        посетить стоматолога, навести красоту у косметолога, сдать широкий
        спектр медицинских анализов!
        <br />
        Здесь Вас окружат заботой и вниманием!
    </>
);

export default about;
