const stomatologyPrices = [
    {
        cat_name: "ОБЩИЕ УСЛУГИ",
        _id: "1001",
        value: [
            {
                _id: "1800",
                code: "null",
                name: "Первичный приём (осмотр, консультация) врача стоматолога:",
                price: null
            },
            {
                _id: "В04065001",
                code: "В04.065.001",
                name: "терапевта",
                price: 350
            },
            {
                _id: "В04067001",
                code: "В04.067.001",
                name: "хирурга",
                price: 350
            },
            {
                _id: "В04066001",
                code: "В04.066.001",
                name: "ортопеда",
                price: 350
            },
            {
                _id: "В01063001",
                code: "В01.063.001",
                name: "ортодонта",
                price: 350
            },
            {
                _id: "1801",
                code: "null",
                name: "Повторный приём (осмотр, консультация) врача стоматолога:",
                price: null
            },
            {
                _id: "В04065002",
                code: "В04.065.002",
                name: "терапевта",
                price: 200
            },
            {
                _id: "В04067002",
                code: "В04.067.002",
                name: "хирурга",
                price: 200
            },
            {
                _id: "В04066002",
                code: "В04.066.002",
                name: "ортопеда",
                price: 200
            },
            {
                _id: "В01063002",
                code: "В01.063.002",
                name: "ортодонта",
                price: 200
            },
            {
                _id: "А0607012",
                code: "А06.07.012",
                name: "Визиография одного зуба (диагностический снимок) ",
                price: 200
            },
            {
                _id: "В01003004004",
                code: "В01.003.004.004",
                name: "Анестезия аппликационная",
                price: 250
            },
            {
                _id: "В01003004005",
                code: "В01.003.004.005",
                name: "Анестезия инфильтрационная, с импортным анестетиком \n(Ubistesine, Ultracain, Septanest, Articaine)\n",
                price: 500
            },
            {
                _id: "B01003004002",
                code: "B01.003.004.002",
                name: "Анестезия проводниковая, с импортным анестетиком \n(Ubistesine, Ultracain)\n",
                price: 500
            },
            {
                _id: "B01003004013",
                code: "B01.003.004.013",
                name: "Дополнительная карпула анестезии ",
                price: 200
            }
        ]
    },
    {
        cat_name: "ТЕРАПИЯ",
        _id: "1002",
        value: [
            {
                _id: "A1730021",
                code: "A17.30.021",
                name: "Коагуляция мягких тканей (десны)",
                price: 300
            },
            {
                _id: "A1107022",
                code: "A11.07.022",
                name: "Медикаментозная обработка десны",
                price: 250
            },
            {
                _id: "A1607092",
                code: "A16.07.092",
                name: "Снятие пломбы, трепанация коронки зуба",
                price: 600
            },
            {
                _id: "A0207002",
                code: "A02.07.002",
                name: "Препарирование кариозной полости",
                price: 600
            },
            {
                _id: "A1607002009",
                code: "A16.07.002.009",
                name: "Наложение временной пломбы (Дентин-паста)",
                price: 350
            },
            {
                _id: "A1607002009001",
                code: "A16.07.002.009.001",
                name: "Временная пломба светового отверждения",
                price: 450
            },
            {
                _id: "А1607025003",
                code: "А16.07.025.003",
                name: "Коррекция пломбы, поставленной в другой клинике",
                price: 300
            },
            {
                _id: "A1607092001",
                code: "A16.07.092.001",
                name: "Раскрытие полости зуба",
                price: 600
            },
            {
                _id: "A1607092002",
                code: "A16.07.092.002",
                name: "Диагностическая ревизия одного зуба",
                price: 1200
            },
            {
                _id: "А1607002005",
                code: "А16.07.002.005",
                name: "Восстановление зуба пломбой с использованием стеклоиномерных цементов ",
                price: 1400
            },
            {
                _id: "A1607002012",
                code: "A16.07.002.012",
                name: "Восстановление зуба пломбой из светоотверждаемого композитного материала, одна поверхность",
                price: 2300
            },
            {
                _id: "A1607002013",
                code: "A16.07.002.013",
                name: "Восстановление зуба пломбой из светоотверждаемого композитного материала, две поверхности",
                price: 2900
            },
            {
                _id: "A1607002014",
                code: "A16.07.002.014",
                name: "Восстановление зуба пломбой из светоотверждаемого композитного материала, три поверхности",
                price: 3400
            },
            {
                _id: "А1607002015",
                code: "А16.07.002.015",
                name: "Восстановление зуба при глубоком кариесе с использованием светоотверждаемой прокладки (Ionosit Baselainer, Calcimol)",
                price: 600
            },
            {
                _id: "А1607002016",
                code: "А16.07.002.016",
                name: "Восстановление зуба при глубоком кариесе с использованием лечебной прокладки (Life, Fuji GC)",
                price: 400
            },
            {
                _id: "А1607002017",
                code: "А16.07.002.017",
                name: "Восстановление зуба при глубоком кариесе с использованием лечебной прокладки  (Рутдент, ProRoot, MTA)",
                price: 700
            },
            {
                _id: "А1607002018",
                code: "А16.07.002.018",
                name: "Неинвазивная герметизация светоотверждаемым герметиком (1 зуб)",
                price: 960
            },
            {
                _id: "А1607002019",
                code: "А16.07.002.019",
                name: "Инвазивная герметизация светоотверждаемым герметиком   (1 зуб)",
                price: 1200
            },
            {
                _id: "A1607003",
                code: "A16.07.003",
                name: "Восстановление зуба композитным виниром",
                price: 6000
            },
            {
                _id: "А1607002020",
                code: "А16.07.002.020",
                name: "Использование жидкотекучего композита при восстановлении зуба ",
                price: 1600
            },
            {
                _id: "А1607025002",
                code: "А16.07.025.002",
                name: "Полировка пломбы",
                price: 350
            },
            {
                _id: "А1607002021",
                code: "А16.07.002.021",
                name: "Изолирующая подкладка Fuji",
                price: 600
            },
            {
                _id: "A1107027",
                code: "A11.07.027",
                name: "Наложение девитализирующей пасты (безмышьяковистой)",
                price: 400
            },
            {
                _id: "А1607002022",
                code: "А16.07.002.022",
                name: "Лечебная повязка с пульпотеком",
                price: 1050
            },
            {
                _id: "А1607002023",
                code: "А16.07.002.023",
                name: "Лечебная повязка с резорцин – формалиновой смесью",
                price: 660
            },
            {
                _id: "А1607002024",
                code: "А16.07.002.024",
                name: "Лечебная повязка с резорцин – формалиновой пастой (форфенан)",
                price: 660
            },
            {
                _id: "А1607002025",
                code: "А16.07.002.025",
                name: "Лечебная повязка (Нон – арсеник)",
                price: 480
            },
            {
                _id: "А1607002026",
                code: "А16.07.002.026",
                name: "Лечебная повязка (кальций – содержащая, Рутдент)",
                price: 600
            },
            {
                _id: "A1607030004",
                code: "A16.07.030.004",
                name: "Инструментальная и медикаментозная обработка 1-го канала ручными инструментами ",
                price: 600
            },
            {
                _id: "A1607030005",
                code: "A16.07.030.005",
                name: "Инструментальная и медикаментозная обработка 1-го канала машинными инструментами",
                price: 800
            },
            {
                _id: "А2207004001",
                code: "А22.07.004.001",
                name: "Обработка 1-го канала ультразвуком (Эндо – чак)",
                price: 300
            },
            {
                _id: "А1607094001",
                code: "А16.07.094.001",
                name: "Извлечение инородного тела из корневого канала",
                price: 650
            },
            {
                _id: "A1607082002",
                code: "A16.07.082.002",
                name: "Распломбировка 1-го канала (пасты типа резорцин-формалин, цемент)",
                price: 600
            },
            {
                _id: "A1607030003",
                code: "A16.07.030.003",
                name: "Временное пломбирование 1-го канала лечебной пастой ",
                price: 350
            },
            {
                _id: "A16070300040",
                code: "A16.07.030.004.0",
                name: "Пломбирование 1-го канала методом латеральной конденсации",
                price: 800
            },
            {
                _id: "A1607031001",
                code: "A16.07.031.001",
                name: "Установка стекловолоконного штифта",
                price: 1200
            },
            {
                _id: "А1607019",
                code: "А16.07.019",
                name: "Шинирование (Ribbond), 1 соединение (СВШ)",
                price: 1400
            },
            {
                _id: "А1607051001",
                code: "А16.07.051.001",
                name: "Комплексная профессиональная гигиена полости рта (УЗ, снятие налет AIR-FLOW, полировка зубов, фторирование гелем)",
                price: 4900
            },
            {
                _id: "А1607051002",
                code: "А16.07.051.002",
                name: "Снятие зубных отложений в области 1 зуба",
                price: 200
            },
            {
                _id: "A1607025001",
                code: "A16.07.025.001",
                name: "Гигиеническая полировка зуба",
                price: 150
            },
            {
                _id: "A1107010",
                code: "A11.07.010",
                name: "Медикаментозная обработка 1-го пародонтального кармана, пародонтальная повязка",
                price: 300
            },
            {
                _id: "A1607025",
                code: "A16.07.025",
                name: "Избирательное пришлифовывание твердых тканей 1 зуба",
                price: 200
            },
            {
                _id: "A1107024",
                code: "A11.07.024",
                name: "Покрытие эмали зуба импортным фтор-лаком, десенситайзером",
                price: 300
            },
            {
                _id: "А2307001",
                code: "А23.07.001",
                name: "Использование системы изоляции коффердам",
                price: 600
            },
            {
                _id: "А2307002",
                code: "А23.07.002",
                name: "Использование  системы изоляции Optragate",
                price: 400
            },
            {
                _id: "A16070500",
                code: "A16.07.050.0",
                name: "Отбеливание по системе ZOOM 4. Акция «Приведи друга»",
                price: 14000
            },
            {
                _id: "A1607050",
                code: "A16.07.050",
                name: "Отбеливание по системе ZOOM 4",
                price: 18000
            },
            {
                _id: "А2307003",
                code: "А23.07.003",
                name: "Назубные украшения (скайс)",
                price: 1500
            }
        ]
    },
    {
        cat_name: "ХИРУРГИЯ",
        _id: "1003",
        value: [
            {
                _id: "A1607001006",
                code: "A16.07.001.006",
                name: " Удаление подвижного зуба",
                price: 600
            },
            {
                _id: "A1607001",
                code: "A16.07.001",
                name: "Удаление зуба, простое",
                price: 1500
            },
            {
                _id: "A1607001002",
                code: "A16.07.001.002",
                name: "Удаление зуба, сложное ",
                price: 2000
            },
            {
                _id: "A1607001004",
                code: "A16.07.001.004",
                name: "Удаление зуба мудрости, простое",
                price: 2500
            },
            {
                _id: "A1607001005",
                code: "A16.07.001.005",
                name: "Удаление зуба мудрости, средней степени сложности",
                price: 3500
            },
            {
                _id: "A1607001003",
                code: "A16.07.001.003",
                name: "Удаление зуба мудрости, сложное",
                price: 4500
            },
            {
                _id: "A1607017002",
                code: "A16.07.017.002",
                name: "Коррекция альвеолярного отростка по ортопедическим показаниям ",
                price: 3200
            },
            {
                _id: "A1607014",
                code: "A16.07.014",
                name: "Вскрытие пародонтального абсцесса",
                price: 550
            },
            {
                _id: "A1607097",
                code: "A16.07.097",
                name: "Наложение швов (1 шов)",
                price: 300
            },
            {
                _id: "A1607097001",
                code: "A16.07.097.001",
                name: "Снятие швов (1 шов), наложенных в другой клинике",
                price: 250
            },
            {
                _id: "A1607007",
                code: "A16.07.007",
                name: "Резекция верхушки корня",
                price: 4500
            },
            {
                _id: "A1607013001",
                code: "A16.07.013.001",
                name: "Лечение альвеолита с ревизией лунки",
                price: 400
            },
            {
                _id: "A1607039",
                code: "A16.07.039",
                name: "Закрытый кюретаж в области 3-х зубов",
                price: 2200
            },
            {
                _id: "A1607038",
                code: "A16.07.038",
                name: "Открытый кюретаж в области 3-х зубов",
                price: 4900
            },
            {
                _id: "A1607012",
                code: "A16.07.012",
                name: "Вскрытие пародонтологического абсцесса, медикаментозная обработка",
                price: 2000
            },
            {
                _id: "A1607040",
                code: "A16.07.040",
                name: "Лоскутная операция в области 1 зуба",
                price: 4000
            },
            {
                _id: "A1507003",
                code: "A15.07.003",
                name: "Лечебная пародонтологическая повязка",
                price: 500
            },
            {
                _id: "A1607040001",
                code: "A16.07.040.001",
                name: "Удлинение клинической коронки одного зуба ",
                price: 2700
            },
            {
                _id: "A1607058",
                code: "A16.07.058",
                name: "Иссечение капюшона при перикоронарите",
                price: 800
            },
            {
                _id: "A1607014001",
                code: "A16.07.014.001",
                name: "Внутриротовой разрез при периоститах; дренирование",
                price: 1100
            },
            {
                _id: "A1507002",
                code: "A15.07.002",
                name: "Применение альвожеля (1 единица)",
                price: 300
            },
            {
                _id: "A15070030",
                code: "A15.07.003.0",
                name: "Применение альвостаза (1 единица)",
                price: 300
            },
            {
                _id: "A1507004",
                code: "A15.07.004",
                name: "Применение неоконуса (1 единица)",
                price: 300
            },
            {
                _id: "A1507005",
                code: "A15.07.005",
                name: "Применение коллапана (1 единица)",
                price: 900
            },
            {
                _id: "A16070010060",
                code: "A16.07.001.006.0",
                name: "Удаление стенки зуба",
                price: 600
            },
            {
                _id: "A1607042",
                code: "A16.07.042",
                name: "Пластика уздечки верхней губы",
                price: 2900
            },
            {
                _id: "A1607043",
                code: "A16.07.043",
                name: "Пластика уздечки нижней губы",
                price: 2900
            },
            {
                _id: "A1607044",
                code: "A16.07.044",
                name: "Пластика уздечки языка",
                price: 2900
            },
            {
                _id: "A1607045",
                code: "A16.07.045",
                name: "Пластика преддверия полости рта ",
                price: 8700
            },
            {
                _id: "A1607045001",
                code: "A16.07.045.001",
                name: "Пластика рецессий микрохирургической техникой в области 1-3-х зубов",
                price: 13500
            },
            {
                _id: "A1601004",
                code: "A16.01.004",
                name: "Антисептическая обработка ран",
                price: 300
            },
            {
                _id: "A1607013",
                code: "A16.07.013",
                name: "Кюретаж лунки",
                price: 400
            },
            {
                _id: "A1607054004",
                code: "A16.07.054.004",
                name: "Формирователь десны",
                price: 7000
            }
        ]
    },
    {
        cat_name: "ОРТОПЕДИЯ",
        _id: "1004",
        value: [
            {
                _id: "1802",
                code: "null",
                name: "Несъёмные протезы",
                price: null
            },
            {
                _id: "A1607004",
                code: "A16.07.004",
                name: "Металлокерамическая коронка",
                price: 9200
            },
            {
                _id: "A1607006009",
                code: "A16.07.006.009",
                name: "Металлокерамическая коронка на импланте",
                price: 25000
            },
            {
                _id: "A1607006010",
                code: "A16.07.006.010",
                name: "Циркониевая коронка на имплантате",
                price: 32000
            },
            {
                _id: "A1607006009001",
                code: "A16.07.006.009.001",
                name: "Установка  абатмента (1 единица)",
                price: 4500
            },
            {
                _id: "A16070060090010",
                code: "A16.07.006.009.001.0",
                name: "Установка индивидуального фрезерованного абатмента",
                price: 8500
            },
            {
                _id: "A2307002081",
                code: "A23.07.002.081",
                name: "Реставрация металлокерамической коронки",
                price: 6900
            },
            {
                _id: "A1607004008",
                code: "A16.07.004.008",
                name: "Ретракция десны",
                price: 200
            },
            {
                _id: "A1607004005",
                code: "A16.07.004.005",
                name: "Цельнолитая коронка",
                price: 4000
            },
            {
                _id: "A1607003001",
                code: "A16.07.003.001",
                name: "Керамическая вкладка",
                price: 14500
            },
            {
                _id: "A1607003002",
                code: "A16.07.003.002",
                name: "Керамический винир",
                price: 22000
            },
            {
                _id: "A1607004006",
                code: "A16.07.004.006",
                name: "Цельнокерамическая коронка (пресс керамика)",
                price: 19000
            },
            {
                _id: "A1607004007",
                code: "A16.07.004.007",
                name: "Цельнокерамическая коронка (циркониевый каркас)",
                price: 22000
            },
            {
                _id: "A1607049",
                code: "A16.07.049",
                name: "Цементировка металлокерамической коронки",
                price: 500
            },
            {
                _id: "A1607049001",
                code: "A16.07.049.001",
                name: "Временная фиксация",
                price: 300
            },
            {
                _id: "A1607049002",
                code: "A16.07.049.002",
                name: "Цементировка винира / вкладки Е-МАХ",
                price: 1700
            },
            {
                _id: "A1607053002",
                code: "A16.07.053.002",
                name: "Снятие штампованной коронки",
                price: 300
            },
            {
                _id: "A1607053001",
                code: "A16.07.053.001",
                name: "Снятие металлокерамической коронки",
                price: 600
            },
            {
                _id: "A2307002082",
                code: "A23.07.002.082",
                name: "Напыление коронки нитрид-титана",
                price: 400
            },
            {
                _id: "A1607004010",
                code: "A16.07.004.010",
                name: " Коронка пластмассовая",
                price: 2000
            },
            { _id: "1803", code: "null", name: "Съёмные протезы", price: null },
            {
                _id: "A1607035001",
                code: "A16.07.035.001",
                name: "Частичный съёмный протез нейлоновый протез до 3-х зубов",
                price: 23000
            },
            {
                _id: "A1607035002",
                code: "A16.07.035.002",
                name: "Частичный съёмный протез нейлоновый протез свыше 3-х зубов",
                price: 30000
            },
            {
                _id: "A1607035",
                code: "A16.07.035",
                name: "Частичный пластиночный съёмный протез ",
                price: 17000
            },
            {
                _id: "A1607023",
                code: "A16.07.023",
                name: "Полный пластиночный съёмный протез",
                price: 22000
            },
            {
                _id: "A1607023001",
                code: "A16.07.023.001",
                name: "Полный съёмный нейлоновый протез",
                price: 34000
            },
            {
                _id: "A1607035003",
                code: "A16.07.035.003",
                name: "Косметическая бабочка",
                price: 8800
            },
            {
                _id: "A2307002074",
                code: "A23.07.002.074",
                name: "Изготовление иммедиат протеза (непосредственно после удаления зубов-временно до 3-х месяцев)",
                price: 5500
            },
            {
                _id: "A2207011",
                code: "A22.07.011",
                name: "Чистка съёмного протеза ультразвуком (нейлон, полиамид)",
                price: 3000
            },
            {
                _id: "A1607036001",
                code: "A16.07.036.001",
                name: "Бюгельный протез на верхнюю / нижнюю челюсти (ацеталовый)",
                price: 37000
            },
            {
                _id: "A1607036002",
                code: "A16.07.036.002",
                name: "Бюгельный протез с замковой фиксацией (1 челюсть) на аттачментах",
                price: 39000
            },
            { _id: "1804", code: "null", name: "Прочие работы", price: null },
            {
                _id: "A1607033",
                code: "A16.07.033",
                name: "Культевая штифтовая вкладка (пост-анкер)",
                price: 4500
            },
            {
                _id: "A1607033001",
                code: "A16.07.033.001",
                name: "Разборная культевая штифтовая вкладка",
                price: 5200
            },
            {
                _id: "A2307002",
                code: "A23.07.002",
                name: "Коррекция протезов, изготовленных в другом медицинском учреждении ",
                price: 3000
            },
            {
                _id: "A2307002001",
                code: "A23.07.002.001",
                name: "Изготовление диагностических моделей (пара)",
                price: 650
            },
            {
                _id: "A0207010002",
                code: "A02.07.010.002",
                name: "Оттиск из импортного материала для металлокерамических коронок (двухслойный)",
                price: 1300
            },
            {
                _id: "A0207010001",
                code: "A02.07.010.001",
                name: "Снятие альгинатного оттиска",
                price: 800
            },
            {
                _id: "A0207006",
                code: "A02.07.006",
                name: "Регистрация прикуса",
                price: 400
            },
            {
                _id: "A2307002046",
                code: "A23.07.002.046",
                name: "Замена атачмена",
                price: 3000
            },
            {
                _id: "1805",
                code: "null",
                name: "Починка протезов",
                price: null
            },
            {
                _id: "A2307002075",
                code: "A23.07.002.075",
                name: "Замена или постановка в съёмном протезе одного дополнительного зуба из пластмассы",
                price: 3000
            },
            {
                _id: "A2307002076",
                code: "A23.07.002.076",
                name: "Замена или постановка в съёмном протезе двух дополнительных зубов из пластмассы",
                price: 4000
            },
            {
                _id: "A2307002077",
                code: "A23.07.002.077",
                name: "Замена или постановка в съёмном протезе трёх дополнительных зубов из пластмассы",
                price: 5000
            },
            {
                _id: "A2307002035",
                code: "A23.07.002.035",
                name: "Приварка кламера",
                price: 3000
            },
            {
                _id: "A2307002078",
                code: "A23.07.002.078",
                name: "Починка перелома протеза",
                price: 3000
            },
            {
                _id: "A2307002079",
                code: "A23.07.002.079",
                name: "Изготовление индивидуальной ложки",
                price: 1800
            },
            {
                _id: "A2307002034",
                code: "A23.07.002.034",
                name: "Перебазировка протеза",
                price: 6000
            }
        ]
    },
    {
        cat_name: "ИМПЛАНТОЛОГИЯ",
        _id: "1005",
        value: [
            {
                _id: "В04067002",
                code: "В04.067.002",
                name: "Консультация хирурга-стоматолога с чтением КТ-снимка и составлением плана лечения",
                price: 1500
            },
            {
                _id: "A1607017002",
                code: "A16.07.017.002",
                name: "Расщепление альвеолярного гребня",
                price: 14000
            },
            {
                _id: "A1630030002",
                code: "A16.30.030.002",
                name: "Пересадка костного аутотрансплантата в области 1 зуба",
                price: 17600
            },
            {
                _id: "A1630030003",
                code: "A16.30.030.003",
                name: "Пересадка костного аутотрансплантата в области 2-3-х соседних зубов",
                price: 25000
            },
            {
                _id: "A1607055",
                code: "A16.07.055",
                name: "Закрытый синус-лифтинг ",
                price: 26000
            },
            {
                _id: "A1607055001",
                code: "A16.07.055.001",
                name: "Открытый синус-лифтинг ",
                price: 36000
            },
            {
                _id: "A1607055002",
                code: "A16.07.055.002",
                name: "Синус-лифтинг одномоментный с имплантацией",
                price: 41000
            },
            {
                _id: "A1607041001",
                code: "A16.07.041.001",
                name: "Применения остеопластического материала (1 зуб) – отечественное производство",
                price: 4000
            },
            {
                _id: "A1607041002",
                code: "A16.07.041.002",
                name: "Применения остеопластического материала (1 зуб) – импортное производство",
                price: 8500
            },
            {
                _id: "A1607054",
                code: "A16.07.054",
                name: "Установка одного имплантата OSSTEM",
                price: 30000
            },
            {
                _id: "A1607054001",
                code: "A16.07.054.001",
                name: "Установка формирователя десны",
                price: 6000
            },
            {
                _id: "A1607054002",
                code: "A16.07.054.002",
                name: "Установка ортодонтического имплантата",
                price: 7000
            },
            {
                _id: "A1607054003",
                code: "A16.07.054.003",
                name: "Резорбируемая мембрана «Bio-Gide»(размер  16*22 мм)",
                price: 21000
            },
            {
                _id: "A1607041003",
                code: "A16.07.041.003",
                name: "Применение костнозамещающего материала Bio-Oss Spongiosa  (0,5 грамм) (или аналог)",
                price: 15000
            },
            {
                _id: "A1607055003",
                code: "A16.07.055.003",
                name: "Применение пины для фиксации мембран",
                price: 6500
            }
        ]
    },
    {
        cat_name: "ОРТОДОНТИЯ",
        _id: "1006",
        value: [
            { _id: "1806", code: "null", name: "Оттиски", price: null },
            {
                _id: "A0207010002",
                code: "A02.07.010.002",
                name: "Снятие оттиска и изготовление гипсовой модели (1 челюсть)",
                price: 500
            },
            {
                _id: "A0207010003",
                code: "A02.07.010.003",
                name: "Снятие оттиска из А-силикона и изготовление модели из супергипса (1 челюсть)",
                price: 800
            },
            { _id: "1807", code: "null", name: "Пластинки", price: null },
            {
                _id: "A1607047006",
                code: "A16.07.047.006",
                name: "Изготовление и фиксация расширяющей пластинки",
                price: 15000
            },
            {
                _id: "A1607047007",
                code: "A16.07.047.007",
                name: "Коррекция пластинки",
                price: 900
            },
            {
                _id: "A2307002080",
                code: "A23.07.002.080",
                name: "Починка пластинки",
                price: "от 3500"
            },
            {
                _id: "1808",
                code: "null",
                name: "Лечение на съемной аппаратуре",
                price: null
            },
            {
                _id: "A1607047001",
                code: "A16.07.047.001",
                name: "Аппарат: LM-активатор, Myobrace, трейнер (цена за аппарат)",
                price: 6000
            },
            {
                _id: "A1607047002",
                code: "A16.07.047.002",
                name: "Сдача трейнеров, LM-активатора, Myobrace (без цены аппарата)",
                price: 3000
            },
            {
                _id: "A1607047003",
                code: "A16.07.047.003",
                name: "Сдача съемного аппарата Twin-Block (цена под ключ)",
                price: 15000
            },
            {
                _id: "A1607047004",
                code: "A16.07.047.004",
                name: "Каппы: спортивная, ретенц, миорелакс. (цена под ключ)",
                price: 4500
            },
            {
                _id: "A1607047005",
                code: "A16.07.047.005",
                name: "Коррекция Винтом Бертони ",
                price: 1500
            },
            {
                _id: "A2307003001",
                code: "A23.07.003.001",
                name: "Припасовка лицевой дуги",
                price: 4500
            },
            {
                _id: "A2307003002",
                code: "A23.07.003.002",
                name: "Припасовка лицевой маски",
                price: 9000
            },
            {
                _id: "A2307003003",
                code: "A23.07.003.003",
                name: "Припасовка губного бампера",
                price: 4000
            },
            {
                _id: "A2307003004",
                code: "A23.07.003.004",
                name: "Припасовка головной шапочки с резиновой тягой",
                price: 4500
            },
            { _id: "1809", code: "null", name: "Брекеты", price: null },
            {
                _id: "A1607048001",
                code: "A16.07.048.001",
                name: "Коррекция металлической брекет-системой Victori, лигатурная (стоимость аппаратуры на 2 челюсти)",
                price: 18000
            },
            {
                _id: "A1607048002",
                code: "A16.07.048.002",
                name: "Коррекция керамической брекет-системой Damon-Q (стоимость аппаратуры на 2 челюсти)",
                price: 58000
            },
            {
                _id: "A1607048003",
                code: "A16.07.048.003",
                name: "Коррекция лингвальной брекет-системой (стоимость на 2 челюсти)",
                price: "от 90000"
            },
            {
                _id: "A1607048004",
                code: "A16.07.048.004",
                name: "Фиксация брекет-системы металлической (1 челюсть)",
                price: 17000
            },
            {
                _id: "A1607048005",
                code: "A16.07.048.005",
                name: "Фиксация безлигатурной брекет-системы (1 челюсть)",
                price: 18000
            },
            {
                _id: "A1607048006",
                code: "A16.07.048.006",
                name: "Фиксация керамической брекет-системы (1 челюсть)",
                price: 20000
            },
            {
                _id: "A1607048007",
                code: "A16.07.048.007",
                name: "Фиксация лингвальной брекет-системы (1 челюсть)",
                price: 28500
            },
            {
                _id: "A1607048008",
                code: "A16.07.048.008",
                name: "Каппы для непрямой фиксации брекетов (1 челюсть)",
                price: 3400
            },
            {
                _id: "A1607048009",
                code: "A16.07.048.009",
                name: "Плановая коррекция металлической брекет-системы (1 челюсть)",
                price: 2000
            },
            {
                _id: "A1607048010",
                code: "A16.07.048.010",
                name: "Плановая коррекция безлигатурной брекет-системы (1 челюсть)",
                price: 3000
            },
            {
                _id: "A1607048011",
                code: "A16.07.048.011",
                name: "Плановая коррекция керамической брекет-системы (1 челюсть)",
                price: 2800
            },
            {
                _id: "A1607048012",
                code: "A16.07.048.012",
                name: "Плановая коррекция лингвальной брекет-системы (1челюсть)",
                price: 4800
            },
            {
                _id: "A1607048013",
                code: "A16.07.048.013",
                name: "Снятие брекетов (1 челюсть)",
                price: 15000
            },
            {
                _id: "A1607048014",
                code: "A16.07.048.014",
                name: "Снятие лингвальных брекетов (1 челюсть)",
                price: 25000
            },
            {
                _id: "А1607051003",
                code: "А16.07.051.003",
                name: "Профессиональная гигиена 1 зубного ряда до/после снятия брекетов + удаление клея",
                price: 3000
            },
            {
                _id: "A1607048015",
                code: "A16.07.048.015",
                name: "Фиксация металлического брекета (1 зуб)",
                price: 700
            },
            {
                _id: "A1607048016",
                code: "A16.07.048.016",
                name: "Фиксация керамического брекета (1 зуб)",
                price: 900
            },
            {
                _id: "A1607048017",
                code: "A16.07.048.017",
                name: "Фиксация безлигатурного брекета (1 зуб) ",
                price: 900
            },
            {
                _id: "A1607048018",
                code: "A16.07.048.018",
                name: "Фиксация 1 кнопки, крючков",
                price: 700
            },
            {
                _id: "A1607048019",
                code: "A16.07.048.019",
                name: "Фиксация 1 подвижного крючка, стопора",
                price: 400
            },
            {
                _id: "A1607048020",
                code: "A16.07.048.020",
                name: "Фиксация 1 специального элемента ",
                price: 1300
            },
            {
                _id: "A2307003005",
                code: "A23.07.003.005",
                name: "Припасовка и наложение дуги ",
                price: 400
            },
            {
                _id: "A2307003006",
                code: "A23.07.003.006",
                name: "Припасовка и наложение дуги на безлигатурном аппарате ",
                price: 550
            },
            {
                _id: "A1607048021",
                code: "A16.07.048.021",
                name: "Подвязывание дуги на один брекет ",
                price: 200
            },
            {
                _id: "A1607048022",
                code: "A16.07.048.022",
                name: "Связывание зубного ряда металлической лигатурой (1 зуб) ",
                price: 120
            },
            {
                _id: "A1607048023",
                code: "A16.07.048.023",
                name: "Фиксация эластичной тяги (1 звено) ",
                price: 100
            },
            {
                _id: "A1607048024",
                code: "A16.07.048.024",
                name: "Фиксация одной пружины (раскрывающей, закрывающей) ",
                price: 350
            },
            {
                _id: "A1607048025",
                code: "A16.07.048.025",
                name: "Фиксация одного брекета",
                price: 1050
            },
            {
                _id: "A1607048026",
                code: "A16.07.048.026",
                name: "Изгиб петли на ортодонтической дуге ",
                price: 450
            },
            {
                _id: "A1607053001",
                code: "A16.07.053.001",
                name: "Фиксация ортодонтического кольца ",
                price: 800
            },
            {
                _id: "A1607048027",
                code: "A16.07.048.027",
                name: "Снятие одного брекета (металлический) ",
                price: 400
            },
            {
                _id: "A1607048028",
                code: "A16.07.048.028",
                name: "Снятие одного брекета (эстетический) ",
                price: 700
            },
            {
                _id: "A1607048029",
                code: "A16.07.048.029",
                name: "Снятие одного брекета (безлигатурный) ",
                price: 700
            },
            {
                _id: "А1607002019",
                code: "А16.07.002.019",
                name: "Накладка на 1 зуб цемента/композита ",
                price: 400
            },
            {
                _id: "A1607048030",
                code: "A16.07.048.030",
                name: "Активация аппарата ",
                price: 800
            },
            {
                _id: "A1607049003",
                code: "A16.07.049.003",
                name: "Цементировка коронки на СИЦ (стеклоиномерные цементы)",
                price: 1500
            },
            {
                _id: "А1607046001",
                code: "А16.07.046.001",
                name: "Изготовление и установка ретейнера",
                price: 8500
            },
            {
                _id: "A1607048031",
                code: "A16.07.048.031",
                name: "Активация брекет-системы",
                price: 3250
            }
        ]
    }
];

export default stomatologyPrices;

export const st = [
    {
        cat_name: "ОБЩИЕ УСЛУГИ",
        _id: "1000",
        value: [
            {
                _id: "0000000",
                code: "0000000",
                name: "Первичный приём (осмотр, консультация) врача стоматолога:",
                price: null
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "терапевта",
                price: 200
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "хирурга",
                price: 350
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "ортопеда",
                price: 350
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "ортодонта",
                price: 350
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "Повторный приём (осмотр, консультация) врача стоматолога:",
                price: null
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "терапевта",
                price: 200
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "хирурга",
                price: 200
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "ортопеда",
                price: 200
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "ортодонта",
                price: 200
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "Визиография одного зуба (диагностический снимок) ",
                price: 200
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "Анестезия аппликационная",
                price: 250
            },
            {
                _id: "0000000",
                code: "0000000",
                name:
                    "Анестезия инфильтрационная, с импортным анестетиком \n" +
                    "(Ubistesine, Ultracain, Septanest, Articaine)\n",
                price: 500
            },
            {
                _id: "0000000",
                code: "0000000",
                name:
                    "Анестезия проводниковая, с импортным анестетиком \n" +
                    "(Ubistesine, Ultracain)\n",
                price: 500
            },
            {
                _id: "0000000",
                code: "0000000",
                name: "Дополнительная карпула анестезии ",
                price: 200
            }
        ]
    },
    {
        cat_name: "ТЕРАПИЯ",
        _id: "1000",
        value: [
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Коагуляция мягких тканей (десны)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Медикаментозная обработка десны`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие пломбы, трепанация коронки зуба`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Препарирование кариозной полости`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Наложение временной пломбы (Дентин-паста)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Временная пломба светового отверждения`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Коррекция пломбы, поставленной в другой клинике`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Раскрытие полости зуба`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Диагностическая ревизия одного зуба`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Восстановление зуба пломбой с использованием стеклоиномерных цементов `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Восстановление зуба пломбой из светоотверждаемого композитного материала, одна поверхность`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Восстановление зуба пломбой из светоотверждаемого композитного материала, две поверхности`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Восстановление зуба пломбой из светоотверждаемого композитного материала, три поверхности`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Восстановление зуба при глубоком кариесе с использованием светоотверждаемой прокладки (Ionosit Baselainer, Calcimol)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Восстановление зуба при глубоком кариесе с использованием лечебной прокладки (Life, Fuji GC)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Восстановление зуба при глубоком кариесе с использованием лечебной прокладки  (Рутдент, ProRoot, MTA)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Неинвазивная герметизация светоотверждаемым герметиком (1 зуб)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Инвазивная герметизация светоотверждаемым герметиком   (1 зуб)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Восстановление зуба композитным виниром`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Использование жидкотекучего композита при восстановлении зуба `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Полировка пломбы`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Изолирующая подкладка Fuji`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Наложение девитализирующей пасты (безмышьяковистой)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Лечебная повязка с пульпотеком`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Лечебная повязка с резорцин – формалиновой смесью`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Лечебная повязка с резорцин – формалиновой пастой (форфенан)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Лечебная повязка (Нон – арсеник)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Лечебная повязка (кальций – содержащая, Рутдент)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Инструментальная и медикаментозная обработка 1-го канала ручными инструментами `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Инструментальная и медикаментозная обработка 1-го канала машинными инструментами`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Обработка 1-го канала ультразвуком (Эндо – чак)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Извлечение инородного тела из корневого канала`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Распломбировка 1-го канала (пасты типа резорцин-формалин, цемент)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Временное пломбирование 1-го канала лечебной пастой `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Пломбирование 1-го канала методом латеральной конденсации`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Установка стекловолоконного штифта`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Шинирование (Ribbond), 1 соединение (СВШ)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Комплексная профессиональная гигиена полости рта (УЗ, снятие налет AIR-FLOW, полировка зубов, фторирование гелем)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие зубных отложений в области 1 зуба`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Гигиеническая полировка зуба`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Медикаментозная обработка 1-го пародонтального кармана, пародонтальная повязка`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Избирательное пришлифовывание твердых тканей 1 зуба`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Покрытие эмали зуба импортным фтор-лаком, десенситайзером`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Использование системы изоляции коффердам`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Использование  системы изоляции Optragate`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Отбеливание по системе ZOOM 4. Акция «Приведи друга»`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Отбеливание по системе ZOOM 4`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Назубные украшения (скайс)`,
                price: 350
            }
        ]
    },
    {
        cat_name: "ХИРУРГИЯ",
        _id: "1000",
        value: [
            {
                _id: `0000000`,
                code: `0000000`,
                name: ` Удаление подвижного зуба`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Удаление зуба, простое`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Удаление зуба, сложное `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Удаление зуба мудрости, простое`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Удаление зуба мудрости, средней степени сложности`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Удаление зуба мудрости, сложное`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Коррекция альвеолярного отростка по ортопедическим показаниям `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Вскрытие пародонтального абсцесса`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Наложение швов (1 шов)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие швов (1 шов), наложенных в другой клинике`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Резекция верхушки корня`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Лечение альвеолита с ревизией лунки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Закрытый кюретаж в области 3-х зубов`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Открытый кюретаж в области 3-х зубов`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Вскрытие пародонтологического абсцесса, медикаментозная обработка`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Лоскутная операция в области 1 зуба`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Лечебная пародонтологическая повязка`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Удлинение клинической коронки одного зуба `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Иссечение капюшона при перикоронарите`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Внутриротовой разрез при периоститах; дренирование`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Применение альвожеля (1 единица)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Применение альвостаза (1 единица)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Применение неоконуса (1 единица)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Применение коллапана (1 единица)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Удаление стенки зуба`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Пластика уздечки верхней губы`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Пластика уздечки нижней губы`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Пластика уздечки языка`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Пластика преддверия полости рта `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Пластика рецессий микрохирургической техникой в области 1-3-х зубов`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Антисептическая обработка ран`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Кюретаж лунки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Формирователь десны`,
                price: 350
            }
        ]
    },
    {
        cat_name: "ОРТОПЕДИЯ",
        _id: "1000",
        value: [
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Несъёмные протезы`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Металлокерамическая коронка`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Металлокерамическая коронка на импланте`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Установка  абатмента (1 единица)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Установка индивидуального фрезерованного абатмента`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Реставрация металлокерамической коронки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Ретракция десны`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Цельнолитая коронка`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Керамическая вкладка`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Керамический винир`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Цельнокерамическая коронка (пресс керамика)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Цельнокерамическая коронка (циркониевый каркас)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Цементировка металлокерамической коронки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Временная фиксация`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Цементировка винира / вкладки Е-МАХ`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие штампованной коронки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие металлокерамической коронки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Напыление коронки нитрид-титана`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: ` Коронка пластмассовая`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Съёмные протезы`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Частичный съёмный протез нейлоновый протез до 3-х зубов`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Частичный съёмный протез нейлоновый протез свыше 3-х зубов`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Частичный пластиночный съёмный протез `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Полный пластиночный съёмный протез`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Полный съёмный нейлоновый протез`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Косметическая бабочка`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Изготовление иммедиат протеза (непосредственно после удаления зубов-временно до 3-х месяцев)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Чистка съёмного протеза ультразвуком (нейлон, полиамид)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Бюгельный протез на верхнюю / нижнюю челюсти (ацеталовый)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Бюгельный протез с замковой фиксацией (1 челюсть) на аттачментах`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Прочие работы`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Культевая штифтовая вкладка (пост-анкер)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Разборная культевая штифтовая вкладка`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Коррекция протезов, изготовленных в другом медицинском учреждении `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Изготовление диагностических моделей (пара)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Оттиск из импортного материала для металлокерамических коронок (двухслойный)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие альгинатного оттиска`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Регистрация прикуса`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Замена атачмена`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Починка протезов`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Замена или постановка в съёмном протезе одного дополнительного зуба из пластмассы`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Замена или постановка в съёмном протезе двух дополнительных зубов из пластмассы`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Замена или постановка в съёмном протезе трёх дополнительных зубов из пластмассы`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Приварка кламера`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Починка перелома протеза`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Изготовление индивидуальной ложки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Перебазировка протеза`,
                price: 350
            }
        ]
    },
    {
        cat_name: "ИМПЛАНТОЛОГИЯ",
        _id: "1000",
        value: [
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Консультация хирурга-стоматолога с чтением КТ-снимка и составлением плана лечения`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Расщепление альвеолярного гребня`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Пересадка костного аутотрансплантата в области 1 зуба`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Пересадка костного аутотрансплантата в области 2-3-х соседних зубов`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Закрытый синус-лифтинг `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Открытый синус-лифтинг `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Синус-лифтинг одномоментный с имплантацией`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Применения остеопластического материала (1 зуб) – отечественное производство`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Применения остеопластического материала (1 зуб) – импортное производство`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Установка одного имплантата OSSTEM`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Установка формирователя десны`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Установка ортодонтического имплантата`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Резорбируемая мембрана «Bio-Gide»(размер  16*22 мм)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Применение костнозамещающего материала Bio-Oss Spongiosa  (0,5 грамм) (или аналог)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Применение пины для фиксации мембран`,
                price: 350
            }
        ]
    },
    {
        cat_name: "ОРТОДОНТИЯ",
        _id: "1000",
        value: [
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Оттиски`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие оттиска и изготовление гипсовой модели (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие оттиска из А-силикона и изготовление модели из супергипса (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Пластинки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Изготовление и фиксация расширяющей пластинки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Коррекция пластинки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Починка пластинки`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Лечение на съемной аппаратуре`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Аппарат: LM-активатор, Myobrace, трейнер (цена за аппарат)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Сдача трейнеров, LM-активатора, Myobrace (без цены аппарата)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Сдача съемного аппарата Twin-Block (цена под ключ)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Каппы: спортивная, ретенц, миорелакс. (цена под ключ)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Коррекция Винтом Бертони `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Припасовка лицевой дуги`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Припасовка лицевой маски`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Припасовка губного бампера`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Припасовка головной шапочки с резиновой тягой`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Брекеты`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Коррекция металлической брекет-системой Victori, лигатурная (стоимость аппаратуры на 2 челюсти)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Коррекция керамической брекет-системой Damon-Q (стоимость аппаратуры на 2 челюсти)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Коррекция лингвальной брекет-системой (стоимость на 2 челюсти)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация брекет-системы металлической (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация безлигатурной брекет-системы (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация керамической брекет-системы (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация лингвальной брекет-системы (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Каппы для непрямой фиксации брекетов (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Плановая коррекция металлической брекет-системы (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Плановая коррекция безлигатурной брекет-системы (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Плановая коррекция керамической брекет-системы (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Плановая коррекция лингвальной брекет-системы (1челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие брекетов (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие лингвальных брекетов (1 челюсть)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Профессиональная гигиена 1 зубного ряда до/после снятия брекетов + удаление клея`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация металлического брекета (1 зуб)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация керамического брекета (1 зуб)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация безлигатурного брекета (1 зуб) `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация 1 кнопки, крючков`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация 1 подвижного крючка, стопора`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация 1 специального элемента `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Припасовка и наложение дуги `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Припасовка и наложение дуги на безлигатурном аппарате `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Подвязывание дуги на один брекет `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Связывание зубного ряда металлической лигатурой (1 зуб) `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация эластичной тяги (1 звено) `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация одной пружины (раскрывающей, закрывающей) `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация одного брекета`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Изгиб петли на ортодонтической дуге `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Фиксация ортодонтического кольца `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие одного брекета (металлический) `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие одного брекета (эстетический) `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Снятие одного брекета (безлигатурный) `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Накладка на 1 зуб цемента/композита `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Активация аппарата `,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Цементировка коронки на СИЦ (стеклоиномерные цементы)`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Изготовление и установка ретейнера`,
                price: 350
            },
            {
                _id: `0000000`,
                code: `0000000`,
                name: `Активация брекет-системы`,
                price: 350
            }
        ]
    }
];

export const pr = [
    null,
    350,
    350,
    350,
    350,
    null,
    200,
    200,
    200,
    200,
    200,
    250,
    500,
    500,
    200,
    300,
    250,
    600,
    600,
    350,
    450,
    300,
    600,
    1200,
    1400,
    2300,
    2900,
    3400,
    600,
    400,
    700,
    960,
    1200,
    6000,
    1600,
    350,
    600,
    400,
    1050,
    660,
    660,
    480,
    600,
    600,
    800,
    300,
    650,
    600,
    350,
    800,
    1200,
    1400,
    4900,
    200,
    150,
    300,
    200,
    300,
    600,
    400,
    14000,
    18000,
    1500,
    600,
    1500,
    2000,
    2500,
    3500,
    4500,
    3200,
    550,
    300,
    250,
    4500,
    400,
    2200,
    4900,
    2000,
    4000,
    500,
    2700,
    800,
    1100,
    300,
    300,
    300,
    900,
    600,
    2900,
    2900,
    2900,
    8700,
    13500,
    300,
    400,
    7000,
    null,
    8800,
    22500,
    4500,
    8500,
    6900,
    200,
    4000,
    14500,
    22000,
    19000,
    22000,
    500,
    300,
    1700,
    300,
    600,
    400,
    2000,
    null,
    23000,
    27000,
    17000,
    22000,
    28000,
    8800,
    5500,
    3000,
    37000,
    39000,
    null,
    4500,
    5200,
    3000,
    650,
    1300,
    800,
    400,
    3000,
    null,
    3000,
    4000,
    5000,
    3000,
    3000,
    1800,
    6000,
    1500,
    14000,
    17600,
    25000,
    26000,
    36000,
    41000,
    4000,
    8500,
    30000,
    6000,
    7000,
    21000,
    15000,
    6500,
    null,
    500,
    800,
    null,
    15000,
    900,
    `от 3500`,
    null,
    6000,
    3000,
    15000,
    4500,
    1500,
    4500,
    9000,
    4000,
    4500,
    null,
    18000,
    58000,
    `от 90000`,
    17000,
    18000,
    20000,
    28500,
    3400,
    2000,
    3000,
    2800,
    4800,
    15000,
    25000,
    3000,
    700,
    900,
    900,
    700,
    400,
    1300,
    400,
    550,
    200,
    120,
    100,
    350,
    1050,
    450,
    800,
    400,
    700,
    700,
    400,
    800,
    1500,
    8500,
    3250
];

export const cd = [
    `null`,
    `В04.065.001`,
    `В04.067.001`,
    `В04.066.001`,
    `В01.063.001`,
    `null`,
    `В04.065.002`,
    `В04.067.002`,
    `В04.066.002`,
    `В01.063.002`,
    `А06.07.012`,
    `В01.003.004.004`,
    `В01.003.004.005`,
    `B01.003.004.002`,
    `B01.003.004.013`,
    `A17.30.021`,
    `A11.07.022`,
    `A16.07.092`,
    `A02.07.002`,
    `A16.07.002.009`,
    `A16.07.002.009.001`,
    `А16.07.025.003`,
    `A16.07.092.001`,
    `A16.07.092.002`,
    `А16.07.002.005`,
    `A16.07.002.012`,
    `A16.07.002.013`,
    `A16.07.002.014`,
    `А16.07.002.015`,
    `А16.07.002.016`,
    `А16.07.002.017`,
    `А16.07.002.018`,
    `А16.07.002.019`,
    `A16.07.003`,
    `А16.07.002.020`,
    `А16.07.025.002`,
    `А16.07.002.021`,
    `A11.07.027`,
    `А16.07.002.022`,
    `А16.07.002.023`,
    `А16.07.002.024`,
    `А16.07.002.025`,
    `А16.07.002.026`,
    `A16.07.030.004`,
    `A16.07.030.005`,
    `А22.07.004.001`,
    `А16.07.094.001`,
    `A16.07.082.002`,
    `A16.07.030.003`,
    `A16.07.030.004`,
    `A16.07.031.001`,
    `А16.07.019`,
    `А16.07.051.001`,
    `А16.07.051.002`,
    `A16.07.025.001`,
    `A11.07.010`,
    `A16.07.025`,
    `A11.07.024`,
    `А23.07.001`,
    `А23.07.002`,
    `A16.07.050`,
    `A16.07.050`,
    `А23.07.003`,
    `A16.07.001.006`,
    `A16.07.001`,
    `A16.07.001.002`,
    `A16.07.001.004`,
    `A16.07.001.005`,
    `A16.07.001.003`,
    `A16.07.017.002`,
    `A16.07.014`,
    `A16.07.097`,
    `A16.07.097.001`,
    `A16.07.007`,
    `A16.07.013.001`,
    `A16.07.039`,
    `A16.07.038`,
    `A16.07.012`,
    `A16.07.040`,
    `A15.07.003`,
    `A16.07.040.001`,
    `A16.07.058`,
    `A16.07.014.001`,
    `A15.07.002`,
    `A15.07.003`,
    `A15.07.004`,
    `A15.07.005`,
    `A16.07.001.006`,
    `A16.07.042`,
    `A16.07.043`,
    `A16.07.044`,
    `A16.07.045`,
    `A16.07.045.001`,
    `A16.01.004`,
    `A16.07.013`,
    `A16.07.054.004`,
    `null`,
    `A16.07.004`,
    `A16.07.006.009`,
    `A16.07.006.009.001`,
    `A16.07.006.009.001`,
    `A23.07.002.081`,
    `A16.07.004.008`,
    `A16.07.004.005`,
    `A16.07.003.001`,
    `A16.07.003.002`,
    `A16.07.004.006`,
    `A16.07.004.007`,
    `A16.07.049`,
    `A16.07.049.001`,
    `A16.07.049.002`,
    `A16.07.053.002`,
    `A16.07.053.001`,
    `A23.07.002.082`,
    `A16.07.004.010`,
    `null`,
    `A16.07.035.001`,
    `A16.07.035.002`,
    `A16.07.035`,
    `A16.07.023`,
    `A16.07.023.001`,
    `A16.07.035.003`,
    `A23.07.002.074`,
    `A22.07.011`,
    `A16.07.036.001`,
    `A16.07.036.002`,
    `null`,
    `A16.07.033`,
    `A16.07.033.001`,
    `A23.07.002`,
    `A23.07.002.001`,
    `A02.07.010.002`,
    `A02.07.010.001`,
    `A02.07.006`,
    `A23.07.002.046`,
    `null`,
    `A23.07.002.075`,
    `A23.07.002.076`,
    `A23.07.002.077`,
    `A23.07.002.035`,
    `A23.07.002.078`,
    `A23.07.002.079`,
    `A23.07.002.034`,
    `В04.067.002`,
    `A16.07.017.002`,
    `A16.30.030.002`,
    `A16.30.030.003`,
    `A16.07.055`,
    `A16.07.055.001`,
    `A16.07.055.002`,
    `A16.07.041.001`,
    `A16.07.041.002`,
    `A16.07.054`,
    `A16.07.054.001`,
    `A16.07.054.002`,
    `A16.07.054.003`,
    `A16.07.041.003`,
    `A16.07.055.003`,
    `null`,
    `A02.07.010.002`,
    `A02.07.010.003`,
    `null`,
    `A16.07.047.006`,
    `A16.07.047.007`,
    `A23.07.002.080`,
    `null`,
    `A16.07.047.001`,
    `A16.07.047.002`,
    `A16.07.047.003`,
    `A16.07.047.004`,
    `A16.07.047.005`,
    `A23.07.003.001`,
    `A23.07.003.002`,
    `A23.07.003.003`,
    `A23.07.003.004`,
    `null`,
    `A16.07.048.001`,
    `A16.07.048.002`,
    `A16.07.048.003`,
    `A16.07.048.004`,
    `A16.07.048.005`,
    `A16.07.048.006`,
    `A16.07.048.007`,
    `A16.07.048.008`,
    `A16.07.048.009`,
    `A16.07.048.010`,
    `A16.07.048.011`,
    `A16.07.048.012`,
    `A16.07.048.013`,
    `A16.07.048.014`,
    `А16.07.051.003`,
    `A16.07.048.015`,
    `A16.07.048.016`,
    `A16.07.048.017`,
    `A16.07.048.018`,
    `A16.07.048.019`,
    `A16.07.048.020`,
    `A23.07.003.005`,
    `A23.07.003.006`,
    `A16.07.048.021`,
    `A16.07.048.022`,
    `A16.07.048.023`,
    `A16.07.048.024`,
    `A16.07.048.025`,
    `A16.07.048.026`,
    `A16.07.053.001`,
    `A16.07.048.027`,
    `A16.07.048.028`,
    `A16.07.048.029`,
    `А16.07.002.019`,
    `A16.07.048.030`,
    `A16.07.049.003`,
    `А16.07.046.001`,
    `A16.07.048.031`
];

export function re(st, pr, cd) {
    let i = 0;
    const newArr = st.map((item) => ({
        ...item,
        value: item.value.map((serv) => ({
            ...serv,
            price: pr[i],
            code: cd[i],
            _id: cd[i++].split(".").join("")
        }))
    }));
    i = 1800;
    const newArr2 = newArr.map((item) => ({
        ...item,
        value: item.value.map((serv) => {
            if (serv._id === "null") {
                return { ...serv, _id: String(i++) };
            } else return serv;
        })
    }));
    console.log(JSON.stringify(newArr2));
}

